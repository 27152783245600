@import './variables';

.clips-carousel-empty-state{
  background-color: $andes-gray-040-solid;
  padding: $andes-spacing-16
}

.clips-carousel-empty-state__container {
  align-items: center;
  background-color: $andes-white;
  border-radius: $border-radius-6;
  display: flex;
  gap: $andes-spacing-12;
  justify-content: center;
  padding: $andes-spacing-12 $andes-spacing-24;
}

.clips-carousel-empty-state__description {
  color: $andes-gray-800;
  font-size: $font-size-14;
}

.clips-empty-state-icon {
  height: $clips-icon-height;
  width: $clips-icon-width;
}