@import 'core';

.ui-recos-carousel-wrapper {
  background-color: $andes-bg-secondary;
  padding-top: rem($andes-spacing-24);
  padding-bottom: rem($andes-spacing-12);

  .andes-carousel-snapped__controls-wrapper {
    height: rem(424px);
  }

  .ui-recos-carousel-wrapper__header {
    align-items: center;
    display: flex;
    margin-left: rem($andes-spacing-20);
    margin-right: rem($andes-spacing-12);
    max-width: 100%;
    justify-content: space-between;
  }

  .ui-recos-carousel-wrapper__title {
    font-size: rem($font-size-16);
    font-weight: $font-weight-semibold;
  }

  .ui-recos-carousel-wrapper__link {
    line-height: rem($font-size-14);

    svg path {
      fill: $andes-blue-500;
    }
  }

  .recommendation-carrousel-wrapper {
    margin-bottom: 0;
  }

  .ui-recos-carousel-wrapper__text {
    color: $andes-gray-550;
    font-size: rem($font-size-14);
    font-weight: $font-weight-semibold;
    padding: rem($andes-spacing-16);
    text-align: center;
  }

  .ui-recos-carousel-wrapper__text svg {
    height: rem(12px);
    width: rem(12px);
  }

  .andes-carousel-free {
    overflow-y: scroll;
  }
}
