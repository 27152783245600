@import '../home';
@import './common';

@import '~@mshops-web-components/newsletter-modal/styles/default/mobile';
// Clips
@import '@clips/carousel/index';
@import '~@mshops-components-library/item/src/styles/mobile';
@import '~@mshops-components-library/item/src/styles/simple/mobile';
@import '~@mshops-components-library/common/src/Components/Section/styles/mobile';
@import '~@mshops-components-library/video-and-text/src/styles/general/mobile';
@import '~@mshops-components-library/video-and-text/src/styles/eshops/mobile';

@import '@mshops-components-library/eshop-item/styles/mobile';
@import 'eshops-components-library/src/components/EmptyState/lib/mobile';
@import 'eshops-components-library/src/components/ProfileHeader/lib/mobile';
@import 'eshops-components-library/src/components/SideBar/lib/mobile';
@import '@mshops-components-library/coupon-carousel/src/styles/mobile';

@import '../../../../components/payment-data/mobile';
@import '../../../../components/badge-carousel/styles/mobile';
@import '../../../../components/site-shopping-info/mobile';
@import '../../../../components/message/styles/mobile';
@import '~@mshops-components-library/categories-gallery/src/styles/base-seller/mobile';
@import '~@mshops-components-library/categories-gallery/src/CategoryLabelImage/styles/base-seller/mobile';
@import '../../../../appearance/buyerInfo/styles/mobile';
@import '../../../../appearance/clipsCarousel/styles/mobile.editable';

// Video player
@import '../../../../components/VideoPlayer/styles/mobile';

//Report Page
@import '../../../../components/report-page/styles/mobile';

@import '../../../../components/recommendation-container/styles/mobile';

// Contact Page
@import '../../../../components/contact-form/styles/mobile';
@import '../../../../components/contact-info/styles/mobile';

// Cancel Buy Landing
@import '../../../../appearance/cancelBuyInfo/styles/mobile';

// Bank Conditions Landing
@import '../../../../appearance/bankConditionsInfo/styles/mobile';

// LGPD Landing
@import '../../../../appearance/dataPrivacyInfo/styles/mobile';

// Legal Notices Landing
@import '../../../../components/LegalNoticesInfo/styles/mobile';

// Adhesion contracts Landing
@import '../../../../appearance/adhesionContractInfo/styles/mobile';

// Recommendation Container
@import '@mshops-components-library/recommendations-carousel/styles/mobile';

// Relevant Item Container
@import '@mshops-components-library/relevant-item-container/styles/mobile';

.carousel-seller {
  background: $andes-white;
}

.home--seller {
  background-color: $andes-white;
  min-height: 100%;

  .ui-ms-section-eshops
    .andes-carousel-snapped__container
    .andes-carousel-snapped__wrapper {
    gap: 0;

    .ui-eshop-item__card--column.ui-eshop-item__card--not-relevant {
      margin: rem($andes-spacing-8) 0 rem($andes-spacing-8)
        rem($andes-spacing-8);

      &:first-child {
        margin-left: rem(8px);
      }
    }
  }

  .ui-ms-section-eshops
    .section-eshop__title--layout
    .section-header__title--base {
    text-align: left;
  }

  .andes-carousel-snapped--scroll-hidden {
    padding: 0;
  }

  .recommendation-carrousel-wrapper.ui-ms-polycard-carousel--recos .andes-carousel-free,
  .mshops-recommendations-wrapper.ui-ms-polycard-carousel .andes-carousel-free {
    margin-right: rem(-$andes-spacing-16);
    margin-left: rem(-$andes-spacing-16);
    padding-left: rem(1px);

    .andes-carousel-free__slide:first-child {
      margin-left: rem($andes-spacing-16);
    }
  }

  .ui-recos-carousel-wrapper {
    .ui-recos-carousel-wrapper__header {
      margin-bottom: rem($andes-spacing-16);
    }

    .ui-ms-section-eshops {
      margin-bottom: 0;
    }
  }

  .hub__boxed-width .content .banner-text {
    margin: 0 !important;
  }
}

.home--nav-menu-eshops {
  overflow: hidden;
}

.ui-ms-products-gallery__polycard .ui-ms-products-gallery__container {
  .andes-card--flat {
    box-shadow: 0 rem(6px) rem($andes-spacing-16) 0 rgba(0, 0, 0, 0.1);
  }
}

.ui-ms-products-gallery__polycard .ui-ms-products-gallery__container li:not(.andes-card.poly-card li) {
  display: flex;
  width: 50%;
}

.ui-ms-products-gallery__polycard--editable .ui-eshop-item__card--seller {
  flex: auto;
  width: 50%;
}


.mshops-recommendations-wrapper.ui-ms-polycard-carousel {
  padding-bottom: 0 !important;

  .poly-card--grid, .poly-card--grid-card {
    --poly-min-width: 160px;
  }
}

.recommendation-carrousel-wrapper.ui-ms-polycard-carousel--recos .poly-card--grid, .poly-card--grid-card {
  --poly-min-width: 160px;
}

div.mshops-recommendations-wrapper.ui-ms-polycard-carousel--offers {
  background-color: $andes-bg-secondary;
  margin-top: 0px !important;
  padding-bottom: rem($andes-spacing-8) !important;
  padding-top: rem($andes-spacing-24) !important;
}

.poly-card--grid-card.poly-card--mobile .poly-card__portada--grid-height, .poly-card--grid.poly-card--mobile .poly-card__portada--grid-height {
  height: auto;
}

.ui-ms-polycard-container {
  .poly-component__title {
    color: $andes-gray-900;
  }

  .poly-column__title:first-child {
    color: $andes-gray-900;
  }

  .poly-card--grid-card {
    width: auto;
    max-width: none;
  }

  .poly-component__picture--square {
    object-fit: contain;
  }

  .poly-card--grid-card.poly-card--mobile .poly-card__portada--grid-height, .poly-card--grid.poly-card--mobile .poly-card__portada--grid-height {
    padding: rem($andes-spacing-16 $andes-spacing-16 0);
  }
}

.ui-relevant-item-container--editable {
  .ui-ms-polycard-container .poly-card--grid-card.poly-card--mobile .poly-card__portada--grid-height, .ui-ms-polycard-container .poly-card--grid.poly-card--mobile .poly-card__portada--grid-height {
    background-image: url('https://http2.mlstatic.com/storage/mshops-appearance-api/resources/default/default-shoes.svg');
    background-position: center;
    background-repeat: no-repeat;
    margin: rem($andes-spacing-12 $andes-spacing-12 0);
    max-height: rem(136px);
  }

  .poly-component__action-links .poly-action-links__action--button .andes-button { 
    background-color: $andes-gray-100;
    color: $andes-gray-900-solid;
    height: rem($andes-spacing-32);
  }
}

.ui-relevant-item-container {
  margin-top: rem($andes-spacing-24);
  margin-bottom: rem($andes-spacing-24);
}
