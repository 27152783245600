@import 'core.mobile';
@import 'player';

/* General styles */
section.video-and-text > .container {
  margin: rem($andes-spacing-16 0);
}

.video-and-text__col.video-and-text__container-video {
  overflow-y: hidden;
  overflow-x: hidden;
}

.video-and-text {
  .video-and-text__container-title {
    color: $andes-gray-900;
    font-size: rem($font-size-16);
    line-height: rem(20px);
  }

  .video-and-text__container-description {
    color: $andes-gray-550;
    font-size: rem($font-size-14);
    font-weight: $font-weight-regular;
    line-height: rem($font-size-18);
  }

  .video-and-text__container .video-and-text__container-title {
    margin: rem(0 0 $andes-spacing-8 0);
  }
}

.video-text--video-variant1 .video-and-text__image--center,
.video-text--video-variant2 .video-and-text__image--center,
.video-text--video-variant3 .video-and-text__image--center,
.video-and-text {
  svg {
    height: rem(48px);
    width: rem(48px);
  }
}

.video-text--video-variant3 .video-and-text.video-and-text__container,
.video-text--video-variant1 .video-and-text.video-and-text__container {
  background: none !important;
}

.video-text--video-variant1 .video-and-text__image--center,
.video-text--video-variant3 .video-and-text__image--center {
  height: auto;
  aspect-ratio: 16 / 9;

  img {
    height: auto;
    aspect-ratio: 16 / 9;
  }
}
/* End General styles */

/* Variant 1 */
.video-text--video-variant1 {
  .video-and-text__col.video-and-text__container-text {
    margin: rem($andes-spacing-24 $andes-spacing-16 0 $andes-spacing-16);
  }
}
/* End Variant 1 */

/* Variant 2 */
.video-text--video-variant2 {
  .video-and-text__image--center {
    aspect-ratio: 16 / 9 !important;
    height: auto;
    position: initial;
  }

  .video-and-text__container-text-variant2 {
    margin: 0;
  }

  .video-and-text {
    svg {
      height: rem(32px);
      margin-top: rem($andes-spacing-16);
      position: initial;
      transform: translate(0);
      width: rem(32px);
    }
  }

  .video-and-text__container-title,
  .video-and-text__container-description {
    color: $andes-white;
  }

  .video-and-text.video-and-text__container {
    aspect-ratio: 16 / 9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    display: flex;
    height: auto;
    padding: rem($andes-spacing-16);
  }

  .video-and-text__image-player {
    display: none;
  }

  .video-and-text.video-and-text__container--v2-playing {
    background: none !important;
    padding: 0;

    .video-and-text__image-player {
      display: block;
      height: 100%;
    }

    svg {
      margin-top: 0;
      position: absolute;
      transform: translate(-14px, -14px);
    }
  }
}
/* End Variant 2 */

/* Variant 3 */
.video-text--video-variant3 {
  .video-and-text__container-text-variant3 {
    margin: rem(0 $andes-spacing-16 $andes-spacing-24 $andes-spacing-16);
  }
}
/* End Variant 3 */

.video-text--video-variant1 .video-and-text__image--center,
.video-text--video-variant3 .video-and-text__image--center {
  height: auto;
  aspect-ratio: 16 / 9;

  .video-and-text__youtube-player,
  .video-and-text__image-player {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.ui-ms-overlay-video-image--show .ui-ms-overlay-video-image {
  height: 100%;
  width: 100%;
}

.video-and-text__container--v2-playing {
  .video-and-text__image--center {
    position: relative;
  }
}

.ui-ms-overlay-video-image.ui-ms-overlay-static {
  bottom: rem(60px);
  width: 100%;
}