@import 'core';

.video-and-text__container-text,
.video-and-text__container-text-variant1,
.video-and-text__container-text-variant2,
.video-and-text__container-text-variant3 {
  font-size: rem($font-size-12);
}

.video-and-text__container {
  margin: 0;
}

.video-text--video-variant2 .video-and-text.video-and-text__container {
  aspect-ratio: auto;
  grid-template-rows: unset;

  .video-and-text__image--center {
    aspect-ratio: auto;
  }
}

.video-text--video-variant2 .video-and-text.video-and-text__container {
  aspect-ratio: inherit;
}

.video-and-text .video-and-text__container-text-variant2 {
  grid-row: 1/2;
}

.video-text--video-variant1 .video-and-text.video-and-text__container {
  margin: unset;
}

.video-text--video-variant1 {
  .video-and-text__col.video-and-text__container-video {
    order: 2;
  }

  .video-and-text__col.video-and-text__container-text {
    margin: rem($andes-spacing-20);
    order: unset;
  }
}

.video-text--video-variant1 .video-and-text__image--center,
.video-text--video-variant3 .video-and-text__image--center {
  aspect-ratio: inherit;
  height: rem(140px);

  .video-and-text__image-player-variant3 {
    height: inherit;
  }

  .video-and-text__youtube-player,
  .video-and-text__image-player {
    height: rem(468px);
    object-fit: cover;
    width: auto;
  }
}

.video-and-text__container .video-and-text__container-description p {
  margin-top: 0;
}